import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    ButtonWrapper,
    CommentButton,
    DescriptionText,
    NetStationInfoWrapper,
    RatingButton,
    TicketInfoWrapper,
    TicketItemContainer,
    TicketItemWrapper,
    TitleText,
    TitleTextL2,
    TitleWrapper
} from './TicketItem.styles';
import { Tag, Collapse } from 'antd';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { CommentOutlined, CopyOutlined } from '@ant-design/icons';

interface Props {
    openCommentDialog: () => void;
    openRatingDialog: () => void;
}

const { Panel } = Collapse;

export const TicketItem: ComponentType<Props> = pure(
    ({ openCommentDialog, openRatingDialog }) => {
        const getStatusTicket = () => {
            return <Tag color="success">Hoàn thành</Tag>;
        };

        const onOpenCommentDialog = () => {
            openCommentDialog();
        };

        const onOpenRatingDialog = () => {
            openRatingDialog();
        };

        return (
            <TicketItemWrapper>
                <TicketItemContainer>
                    <TicketInfoWrapper>
                        <TitleWrapper>
                            <TitleText>Mã yêu cầu: #135</TitleText>
                            {getStatusTicket()}
                        </TitleWrapper>
                        <TitleWrapper>
                            <DescriptionText>Nội dung :&nbsp;</DescriptionText>
                            <TitleTextL2>
                                Phòng máy không update được game, vui lòng hỗ
                                trợ giúp
                            </TitleTextL2>
                        </TitleWrapper>
                        <TitleWrapper>
                            <DescriptionText>
                                Tài khoản UltraView :&nbsp;
                            </DescriptionText>
                            <TitleTextL2>Duc Le / 123456</TitleTextL2>
                        </TitleWrapper>

                        <NetStationInfoWrapper>
                            <Panel header="Thông tin phòng máy" key={1}>
                                <TitleWrapper>
                                    <DescriptionText>
                                        Tên phòng máy :&nbsp;
                                    </DescriptionText>
                                    <TitleTextL2>Phòng máy VIP</TitleTextL2>
                                </TitleWrapper>
                                <TitleWrapper>
                                    <DescriptionText>
                                        Mã số phòng máy :&nbsp;
                                    </DescriptionText>
                                    <TitleTextL2>94885kf12459596</TitleTextL2>

                                    <CopyOutlined
                                        style={{
                                            fontSize: 20,
                                            color: '#54b4a4',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </TitleWrapper>
                                <TitleWrapper>
                                    <DescriptionText>
                                        Chủ phòng máy :&nbsp;
                                    </DescriptionText>
                                    <TitleTextL2>Lê Đức</TitleTextL2>
                                    <TitleTextL2>- 0902615767</TitleTextL2>
                                    <CopyOutlined
                                        style={{
                                            fontSize: 20,
                                            color: '#54b4a4',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </TitleWrapper>
                                <TitleWrapper>
                                    <DescriptionText>
                                        Địa chỉ :&nbsp;
                                    </DescriptionText>
                                    <TitleTextL2>
                                        182 Lê Đại Hành P15, Q10
                                    </TitleTextL2>
                                </TitleWrapper>
                            </Panel>
                        </NetStationInfoWrapper>
                    </TicketInfoWrapper>
                    <ButtonWrapper>
                        <RatingButton
                            typeButton={APP_BUTTON_TYPE.PRIMARY}
                            onClick={() => onOpenRatingDialog()}>
                            Đánh giá hỗ trợ
                        </RatingButton>
                        <CommentButton
                            typeButton={APP_BUTTON_TYPE.BORDER}
                            onClick={() => onOpenCommentDialog()}>
                            <CommentOutlined
                                style={{ fontSize: 25, color: '#54b4a4' }}
                            />
                        </CommentButton>
                    </ButtonWrapper>
                </TicketItemContainer>
            </TicketItemWrapper>
        );
    }
);
