import { LOCAL_KEY } from './configs/AppKey';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './translations/en.json';
import vi from './translations/vi.json';

export const defaultNS = 'vi';
export const resources = {
    vi,
    en
};

const userLanguage = localStorage.getItem(LOCAL_KEY.USER_LANGUAGE);

i18n.use(initReactI18next)
    //.use(LanguageDetector)
    .init({
        // if you're using a language detector, do not define the lng option
        lng: !userLanguage ? 'vi' : userLanguage,
        ns: ['vi', 'en'],
        resources,
        fallbackLng: !userLanguage ? 'vi' : userLanguage,
        defaultNS,
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

export default i18n;
