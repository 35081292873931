import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import pure from 'recompose/pure';
import { ITabMenu } from '../../types/IGlobal';

//styles
import {
    TabBarWrapper,
    TabContainer,
    TabBarContainer,
    TabItem,
    TabLink
} from './TabBar.styles';

interface Props {
    tabs: ITabMenu[];
    defaultTab?: number;
    onChangeTab: (tab: number) => void;
}

export const TabBar: ComponentType<Props> = pure(
    ({ tabs, defaultTab, onChangeTab }) => {
        const { t } = useTranslation();

        const [tabIndex, setTabIndex] = React.useState(defaultTab);

        const renderTabItem = (tab: ITabMenu) => {
            const active = tab.id === tabIndex ? 'active' : 'disabled';
            return (
                <TabItem key={tab.id} onClick={() => onItemMenuClick(tab.id)}>
                    <TabLink active={active}>{t(tab.title)}</TabLink>
                </TabItem>
            );
        };

        const onItemMenuClick = (tab: number) => {
            setTabIndex(tab);
            onChangeTab(tab);
        };

        return (
            <TabBarWrapper>
                <TabBarContainer>
                    <TabContainer>
                        {tabs.map((tab: ITabMenu) => {
                            return renderTabItem(tab);
                        })}
                    </TabContainer>
                </TabBarContainer>
            </TabBarWrapper>
        );
    }
);
