import { INews } from './../types/IJson';
import { makeObservable, action, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import JSonService from '../services/JSonService';

class JSonStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable newsContent: string | null = null;

    @observable listNews: Array<INews> | null = null;

    //declare action
    @action getListNews = async () => {
        const result = (await JSonService.getInstance().getData(
            ''
        )) as Array<INews>;
        if (result) {
            runInAction(() => {
                this.listNews = result;
            });
        }
    };

    @action getNewsContent = async (url: string) => {
        const result = (await JSonService.getInstance().getData(url)) as string;
        if (result) {
            runInAction(() => {
                this.newsContent = result;
            });
        }
    };
}
export default JSonStore;
