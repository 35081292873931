import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRouters from './configs/AppRouters';
import { useRootStore } from './providers/RootStoreProvider';
import { LOCAL_KEY } from './configs/AppKey';

//css import
import 'antd/dist/antd.min.css';
import './styles/AntdDesign.css';
import GlobalStyle from './styles/GlobalStyle';

const App: React.FC = () => {
    const store = useRootStore();
    const { changeLanguage } = store.globalStore;

    useEffect(() => {
        onInitAppLanguage();
    }, []);

    //init default app language
    const onInitAppLanguage = () => {
        const key = localStorage.getItem(LOCAL_KEY.USER_LANGUAGE);
        if (key && key == 'en') changeLanguage(key);
        else changeLanguage('vi');
    };

    return (
        <BrowserRouter>
            <GlobalStyle />
            <AppRouters />
        </BrowserRouter>
    );
};

export default App;
