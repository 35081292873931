import { AuthHeader } from '../helpers/AuthHeader';
import { IUser } from '../types/IUser';
export default class User {
    public userId: number;
    public name: string;
    public email: string;
    public code: string;
    public avatar: string;

    public constructor(
        userId: number,
        name: string,
        email: string,
        code: string,
        avatar: string
    ) {
        this.userId = userId;
        this.name = name;
        this.email = email;
        this.code = code;
        this.avatar = avatar;
    }

    static fromJson = (json: IUser): User => {
        return new User(
            json.userId,
            json.fullName,
            json.email,
            json.code,
            json.avatar
        );
    };

    isLogin = (): boolean => {
        return AuthHeader() != null || this != null;
    };
}
