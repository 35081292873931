import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components
import { UserLogin } from './UserLogin';
//styles
import {
    FuDrawer,
    MenuContainer,
    MenuItem,
    MenuLink
} from './MenuDrawer.styles';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { MAIN_MENU } from '../../../../types/IGlobal';

interface Props {
    open: boolean;
    onClose: () => void;
}

export const MenuDrawer: ComponentType<Props> = pure(({ open, onClose }) => {
    const store = useRootStore();
    const { currentUser, logout } = store.authStore;
    const { indexMainMenu, changeMainMenuIndex } = store.globalStore;

    const onChangeMainMenuIndex = (index: MAIN_MENU) => {
        onClose();
        changeMainMenuIndex(index);
    };

    return (
        <FuDrawer title="" placement="right" onClose={onClose} open={open}>
            <UserLogin />
            <MenuContainer>
                <MenuLink
                    onClick={() => onChangeMainMenuIndex(MAIN_MENU.FEEDBACK)}
                    isActive={
                        indexMainMenu == MAIN_MENU.FEEDBACK ? 'active' : ''
                    }
                    to="/feedback">
                    <MenuItem
                        isActive={
                            indexMainMenu == MAIN_MENU.FEEDBACK ? 'active' : ''
                        }>
                        GÓP Ý SẢN PHẨM
                    </MenuItem>
                </MenuLink>
                <MenuLink
                    onClick={() =>
                        onChangeMainMenuIndex(MAIN_MENU.NET_STATION_INFO)
                    }
                    isActive={
                        indexMainMenu == MAIN_MENU.NET_STATION_INFO
                            ? 'active'
                            : ''
                    }
                    to="/net-station">
                    <MenuItem
                        isActive={
                            indexMainMenu == MAIN_MENU.NET_STATION_INFO
                                ? 'active'
                                : ''
                        }>
                        DANH SÁCH PHÒNG MÁY
                    </MenuItem>
                </MenuLink>
                <MenuLink
                    onClick={() => onChangeMainMenuIndex(MAIN_MENU.TICKET)}
                    isActive={indexMainMenu == MAIN_MENU.TICKET ? 'active' : ''}
                    to="/ticket">
                    <MenuItem
                        isActive={
                            indexMainMenu == MAIN_MENU.TICKET ? 'active' : ''
                        }>
                        YÊU CẦU HỖ TRỢ
                    </MenuItem>
                </MenuLink>
            </MenuContainer>
        </FuDrawer>
    );
});
