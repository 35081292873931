import styled from 'styled-components';
import { AppButton } from '../../../../styles/AppButton.styles';

export const UserLoginWrapper = styled.div`
    height: 150px;
    background-color: #19181c;
`;

export const UserLoginContainer = styled.div``;

export const LoginButton = styled(AppButton)``;

export const LogoutButton = styled(AppButton)``;

export const ProfileButton = styled(AppButton)``;

export const BagButton = styled(AppButton)``;
