import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    CommentModal,
    DialogTitleContainer,
    DialogTitleText,
    TicketCommentWrapper,
    CommentListWrapper,
    CommentInputWrapper,
    SendComment,
    CommentInputText
} from './TicketCommentDialog.styles';
import { CommentItem } from './CommentItem/CommentItem';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';

interface Props {
    visible: boolean;
    onClose: () => void;
}

export const TicketCommentDialog: ComponentType<Props> = pure(
    ({ visible, onClose }) => {
        return (
            <TicketCommentWrapper>
                <CommentModal
                    centered
                    onCancel={onClose}
                    open={visible}
                    footer={null}>
                    <DialogTitleContainer>
                        <DialogTitleText>Thảo luận hỗ trợ</DialogTitleText>
                    </DialogTitleContainer>
                    <CommentListWrapper>
                        <CommentItem />
                        <CommentItem />
                        <CommentItem />
                        <CommentItem />
                        <CommentItem />
                        <CommentItem />
                        <CommentItem />
                        <CommentItem />
                        <CommentItem />
                    </CommentListWrapper>
                    <CommentInputWrapper>
                        <CommentInputText />
                        <SendComment typeButton={APP_BUTTON_TYPE.PRIMARY}>
                            Gửi
                        </SendComment>
                    </CommentInputWrapper>
                </CommentModal>
            </TicketCommentWrapper>
        );
    }
);
