import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';
import { Link, NavLink } from 'react-router-dom';
import { Dropdown } from 'antd';
import { AppButton } from '../../../../styles/AppButton.styles';

export const HeaderWrapper = styled.div`
    width: 100%;
    min-height: 70px;
    background-color: #19181c;
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 30px;
`;

export const MenuContainer = styled.ul`
    display: flex;
    margin: 0;
    padding-left: 0px;
    list-style-type: none;
    justify-content: space-between;
`;

export const LogoutButton = styled(AppButton)`
    height: 40px;
    width: 150px;
`;

export const UserNameWrapper = styled.div`
    display: flex;
    width: 200px;
    justify-content: end;
`;

export const AppLogoContainer = styled.div`
    width: 110px;
    height: 40px;
    padding-top: 15px;
`;

export const AppLogoImage = styled.img`
    object-fit: cover;
    max-width: 100%;
`;

interface MenuItemProps {
    isActive: boolean;
}

export const MenuItem = styled.li.attrs((props: MenuItemProps) => ({
    className: props.isActive
}))`
    padding-top: 17px;
    margin-right: 30px;
    padding-bottom: 17px;

    &.active {
        border-bottom: solid 5px #54b4a4;
    }

    &:hover {
        border-bottom: solid 5px #54b4a4;
    }
`;

export const MenuLink = styled(Link).attrs((props: MenuItemProps) => ({
    className: props.isActive
}))`
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    &:hover {
        color: #54b4a4;
    }
    &:focus {
        //color: #54b4a4;
    }
    &.active {
        color: #54b4a4;
    }
`;
