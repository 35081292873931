import { Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
    
} from './Footer.styles';

export const Footer: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            
        </>
    );
};
