import styled from 'styled-components';
import { AppButton } from '../../../../styles/AppButton.styles';
import { device } from '../../../../configs/MediaQuery';
import Image from 'rc-image';

export const UserLoginWrapper = styled.div`
    height: 160px;
    background-color: #19181c;
    padding-left: 20px;
    padding-right: 20px;
`;

export const UserLoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const AvatarWrapper = styled.div`
    position: relative;
    @media ${device.mobileS} {
        margin-bottom: 20px;
    }
    @media ${device.laptop} {
        margin-bottom: 0px;
    }
`;

export const AvatarImage = styled(Image)`
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 40px;
    border: solid 4px #fff;
    position: relative;

    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
    }
`;

export const LoginButton = styled(AppButton)``;

export const LogoutButton = styled(AppButton)`
    width: 100%;
    height: 40px;
`;

export const ProfileButton = styled(AppButton)``;

export const BagButton = styled(AppButton)``;
