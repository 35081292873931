import { makeObservable } from 'mobx';
import { AuthHeader } from '../helpers/AuthHeader';
import { IUserProfile } from '../types/IUserProfile';

export class UserProfile {
    userId: number;
    name: string;
    code: string;
    avatar: string;

    constructor(userId: number, name: string, code: string, avatar: string) {
        makeObservable(this);

        this.userId = userId;
        this.name = name;
        this.code = code;
        this.avatar = avatar;
    }

    static fromJson = (json: IUserProfile): UserProfile => {
        const userProfile = new UserProfile(
            json.userId,
            json.name,
            json.code,
            json.ava
        );
        return userProfile;
    };
}
