import styled from 'styled-components';

export const TicketTitle = styled.h1`
    margin: 0;
    font-size: 25px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
`;

export const TicketListWrapper = styled.div`
    border: solid 1px #cfd4d1;
    margin-top: 30px;
`;
