import { Col, Collapse, Select } from 'antd';
import styled from 'styled-components';
import { AppInputText } from '../../../styles/AppInput.styles';
import { device } from '../../../configs/MediaQuery';
import { AppButton } from '../../../styles/AppButton.styles';

export const NetStationFilterWrapper = styled.div``;

export const NetStationFilterContainer = styled(Collapse)`
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: solid 1px #cfd4d1;
    .ant-collapse-item {
        border: 0 !important;
    }
    .ant-collapse-header {
        background-color: #edf0ee;
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }
    .ant-collapse-content {
        border: 0 !important;
        border-radius: 10px !important;
    }
    .ant-collapse-header-text {
        font-weight: bold;
    }
`;

export const FilterContentWrapper = styled.div``;

export const TitleText = styled.h1`
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    margin-right: 20px;
`;

export const TitleTextL2 = styled.h1`
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
`;

export const DescriptionText = styled.h2`
    margin: 0;
    font-size: 14px;
    min-width: 160px;
    font-weight: 500;
    color: #8d9199;
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

    @media ${device.mobileS} {
        margin-bottom: 10px;
    }
    @media ${device.laptop} {
        margin: 0;
        margin-right: 10px;
    }
`;

export const InputText = styled(AppInputText)`
    &.error {
        margin: 0;
    }
`;

export const LabelText = styled.p`
    margin: 0;
    font-size: 14px;
    min-width: 160px;
    font-weight: 500;
    color: #8d9199;
`;

export const SearchButtonWrapper = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const SearchButton = styled(AppButton)`
    height: 40px;
    font-size: 14px !important;
    width: 100%;
`;

export const StatusNetStationFilter = styled(Select)`
    .ant-select-selector {
        height: 40px !important;
        border-radius: 10px !important;
    }
    .ant-select-selection-item {
        display: flex;
        align-items: center;
    }
`;
