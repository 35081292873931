import {
    IUserFeedbackRequest,
    IGetUserProfileDetailRequest
} from '../types/IUser';
import { handleError } from '../helpers/APIHelper';
import BaseAuthService from './BaseAuthService';
import { API } from '../configs/APIEndPoint';

class UserService extends BaseAuthService {
    private static instance: UserService;

    public static getInstance(): UserService {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }

    //methods api
    sendFeedback = async (params: IUserFeedbackRequest) => {
        try {
            const response = await this.postData(
                API.USER.PROFILE_DETAIL,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    //get user profile details
    getProfileDetail = async (params: IGetUserProfileDetailRequest) => {
        try {
            const response = await this.postData(
                API.USER.PROFILE_DETAIL,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}
export default UserService;
