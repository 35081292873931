import { v4 } from 'uuid';
import { Buffer } from 'buffer';

export function getUUID() {
    return v4().toString();
}

export function getTimestamp() {
    return Math.floor(Date.now() / 1000).toString();
}

export function stripedHtml(val: any) {
    if (!val) return null;
    val = val.replace(/<[^>]+>/g, '');
    //val = val.replace(/[^a-zA-Z0-9,/ ]/g, '');
    return val;
}
