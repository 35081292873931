export const API_STATUS = {
    OK: 0,
    LOGIN_SUCCESS: 1,
    OVER_QUOTA: 122,
    LOGIN: {
        CAPTCHA_INVALID: 1009,
        USER_NOT_FOUND: 2000,
        USER_LOGIN_FAIL: 2017
    },
    USER: {}
};
