import * as Yup from 'yup';

export const AddTicketSchema = Yup.object().shape({
    netId: Yup.string().required('Chọn phòng máy cần hỗ trợ'),
    ultraAccount: Yup.string().required('Cung cấp tài khoản UltraView'),
    ultraPass: Yup.string().required('Cung cấp mật khẩu UltraView'),
    contentRequest: Yup.string().required(
        'Nội dung yêu cầu hỗ trợ không được trống'
    )
});
