import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    AvatarImage,
    AvatarWrapper,
    CommentContent,
    CommentDate,
    CommentInfoWrapper,
    CommentItemContainer,
    CommentItemWrapper,
    UserName
} from './CommentItem.styles';
import { humanTime } from '../../../../utils/DateUtils';

interface Props {}

export const CommentItem: ComponentType<Props> = pure(({}) => {
    return (
        <CommentItemWrapper>
            <CommentItemContainer>
                <AvatarWrapper>
                    <AvatarImage
                        src={''}
                        placeholder={true}
                        preview={false}
                        fallback="../../../../images/default-avatar.png"></AvatarImage>
                </AvatarWrapper>
                <CommentInfoWrapper>
                    <UserName>Duc Le</UserName>
                    <CommentContent>
                        Cai này de the mà không làm được hả bạn
                    </CommentContent>
                    <CommentDate>{humanTime('2023-08-25', 'vi')}</CommentDate>
                </CommentInfoWrapper>
            </CommentItemContainer>
        </CommentItemWrapper>
    );
});
