import styled from 'styled-components';
import { device } from '../../../../configs/MediaQuery';
import Image from 'rc-image';

export const CommentItemWrapper = styled.div`
    margin-bottom: 10px;
    background-color: #f0efed;
    border: 1px solid #f0efed;
    border-radius: 20px;
    padding: 10px;
`;

export const CommentItemContainer = styled.div`
    display: flex;
`;

export const AvatarWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${device.mobileS} {
        margin-bottom: 20px;
    }
    @media ${device.laptop} {
        margin-bottom: 0px;
    }
`;

export const AvatarImage = styled(Image)`
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 30px;
    border: solid 4px #fff;
    position: relative;

    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
    }
`;

export const CommentInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    //background-color: red;
`;

export const UserName = styled.h1`
    margin: 0px 0px 0px 0px; //top - right - bottom - left
    font-size: 14px;
    font-weight: bold;
`;

export const CommentContent = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    //color: #8d9199;
`;

export const CommentDate = styled.p`
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    color: #8d9199;
`;
