import { yupResolver } from '@hookform/resolvers/yup';
import { ComponentType } from 'react';
import { useForm } from 'react-hook-form';
import pure from 'recompose/pure';
import { FeedbackSchema } from '../../validation/FeedbackSchema';
import { IFeedbackProduct, IRequestAddGame } from '../../types/IFeedback';
import {
    ErrorText,
    RequestGameContainer,
    RequestGameWrapper,
    InputText,
    InputTextMultiline,
    InputWrapper,
    LabelText,
    SendButton
} from './RequestGame.styles';
import { APP_BUTTON_TYPE } from '../../types/IGlobal';
import { Select } from 'antd';
import { RequestAddGameSchema } from '../../validation/RequestAddGameSchema';

//components

//styles

interface Props {}

export const RequestGame: ComponentType<Props> = pure(({}) => {
    const {
        register,
        handleSubmit,
        reset,
        resetField,
        formState: { errors }
    } = useForm<IRequestAddGame>({
        resolver: yupResolver(RequestAddGameSchema)
    });

    const onSubmit = () => {};

    const onChangeProduct = () => {};

    return (
        <RequestGameWrapper>
            <RequestGameContainer>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputWrapper>
                        <LabelText>Tên game</LabelText>
                        <InputText
                            placeholder=""
                            type="text"
                            {...register('gameName')}
                            isError={errors.gameName ? 'error' : ''}
                        />
                        {errors.gameName ? (
                            <ErrorText>{errors.gameName.message}</ErrorText>
                        ) : null}
                    </InputWrapper>
                    <InputWrapper>
                        <LabelText>Tên nhà phát hành</LabelText>
                        <InputText
                            placeholder=""
                            type="text"
                            {...register('companyName')}
                            isError={errors.companyName ? 'error' : ''}
                        />
                        {errors.companyName ? (
                            <ErrorText>{errors.companyName.message}</ErrorText>
                        ) : null}
                    </InputWrapper>
                    <InputWrapper>
                        <LabelText>Đường dẫn tham khảo (nếu có)</LabelText>
                        <InputText placeholder="" type="text"></InputText>
                    </InputWrapper>

                    <InputWrapper>
                        <LabelText>Ghi chú</LabelText>
                        <InputTextMultiline
                            placeholder=""
                            type="password"></InputTextMultiline>
                    </InputWrapper>

                    <SendButton typeButton={APP_BUTTON_TYPE.PRIMARY}>
                        Gửi yêu cầu
                    </SendButton>
                </form>
            </RequestGameContainer>
        </RequestGameWrapper>
    );
});
