import {
    IChangePassRequest,
    IForgetPassRequest,
    ILoginBase,
    ILoginEmailRequest,
    ILoginResponse,
    IVerifyEmailRequest,
    IVerifyPhoneRequest
} from './../types/ILogin';
import { LOCAL_KEY } from '../configs/AppKey';
import APIHelper, { handleError, handleResponse } from '../helpers/APIHelper';
import { API } from '../configs/APIEndPoint';
import { IRequestBase, IResponseBase } from '../types/ITypeBase';
import BaseAuthService from './BaseAuthService';
class AuthService extends BaseAuthService {
    private static instance: AuthService;

    public static getInstance(): AuthService {
        if (!AuthService.instance) {
            AuthService.instance = new AuthService();
        }
        return AuthService.instance;
    }

    login = async (data: IRequestBase) => {
        try {
            const response = await APIHelper.post(
                API.AUTHENTICATION.LOGIN,
                data
            );
            return this.handleLoginResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    //get user from localStorage if available
    getCurrentUser = (): ILoginResponse | null => {
        const userStr = localStorage.getItem(LOCAL_KEY.CURRENT_USER);
        if (userStr) {
            let objUser: ILoginResponse = JSON.parse(userStr);
            return objUser;
        }
        return null;
    };

    forgetPass = async (params: IForgetPassRequest) => {
        try {
            const response = await this.postData(
                API.AUTHENTICATION.FORGOT_PASS,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };

    changePass = async (params: IChangePassRequest) => {
        try {
            const response = await this.postData(
                API.AUTHENTICATION.CHANGE_PASS,
                params
            );
            return this.handleResponse(response);
        } catch (error: any) {
            return handleError(error);
        }
    };
}

export default AuthService;
