import { yupResolver } from '@hookform/resolvers/yup';
import { ComponentType } from 'react';
import { useForm } from 'react-hook-form';
import pure from 'recompose/pure';
import { FeedbackSchema } from '../../validation/FeedbackSchema';
import { IFeedbackProduct } from '../../types/IFeedback';
import {
    ErrorText,
    FeedbackInfoContainer,
    FeedbackInfoWrapper,
    InputText,
    InputTextMultiline,
    InputWrapper,
    LabelText,
    SendButton
} from './FeedbackInfo.styles';
import { APP_BUTTON_TYPE } from '../../types/IGlobal';
import { Select } from 'antd';

//components

//styles

interface Props {}

export const FeedbackInfo: ComponentType<Props> = pure(({}) => {
    const {
        register,
        handleSubmit,
        reset,
        resetField,
        formState: { errors }
    } = useForm<IFeedbackProduct>({
        resolver: yupResolver(FeedbackSchema)
    });

    const onSubmit = () => {};

    const onChangeProduct = () => {};

    return (
        <FeedbackInfoWrapper>
            <FeedbackInfoContainer>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputWrapper>
                        <LabelText>Sản phẩm góp ý</LabelText>
                        <Select
                            defaultValue="lucy"
                            style={{ width: '100%' }}
                            onChange={onChangeProduct}
                            options={[
                                { value: 'jack', label: 'Jack' },
                                { value: 'lucy', label: 'Lucy' },
                                { value: 'Yiminghe', label: 'yiminghe' },
                                {
                                    value: 'disabled',
                                    label: 'Disabled',
                                    disabled: true
                                }
                            ]}
                        />
                        {errors.content ? (
                            <ErrorText>{errors.content.message}</ErrorText>
                        ) : null}
                    </InputWrapper>
                    <InputWrapper>
                        <LabelText>Nội dung góp ý</LabelText>
                        <InputTextMultiline
                            placeholder=""
                            type="password"
                            {...register('content')}
                            isError={errors.content ? 'error' : ''}
                        />
                        {errors.content ? (
                            <ErrorText>{errors.content.message}</ErrorText>
                        ) : null}
                    </InputWrapper>
                    <SendButton typeButton={APP_BUTTON_TYPE.PRIMARY}>
                        Gửi thông tin
                    </SendButton>
                </form>
            </FeedbackInfoContainer>
        </FeedbackInfoWrapper>
    );
});
