import { ComponentType, useState } from 'react';
import { pure } from 'recompose';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

//components
import { MenuDrawer } from '../MenuDrawer/MenuDrawer';
//styles
import {
    HeaderWrapper,
    AppLogoContainer,
    HeaderContainer,
    AppLogoImage,
    MenuWrapper
} from './HeaderMobile.styles';
import { Link } from 'react-router-dom';
import { MenuIcon } from '../../../../components/SvgIcon';

interface Props {}

export const HeaderMobile: ComponentType<Props> = pure(
    observer(({}) => {
        const [open, setOpen] = useState(false);

        const showDrawer = () => {
            setOpen(true);
        };

        const onClose = () => {
            setOpen(false);
        };

        return (
            <HeaderWrapper>
                <HeaderContainer>
                    <Link to={'/'}>
                        <AppLogoContainer title="1.0.3">
                            <AppLogoImage src="../../../images/app_logo.png"></AppLogoImage>
                        </AppLogoContainer>
                    </Link>
                    <MenuWrapper onClick={showDrawer}>
                        <MenuIcon />
                    </MenuWrapper>
                </HeaderContainer>
                <MenuDrawer open={open} onClose={() => onClose()} />
            </HeaderWrapper>
        );
    })
);
