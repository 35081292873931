import styled from 'styled-components';
import { AppButton } from '../../styles/AppButton.styles';
import {
    AppInputText,
    AppInputTextMultiline
} from '../../styles/AppInput.styles';

export const FeedbackInfoWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
`;

export const FeedbackInfoContainer = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
`;

export const SendButton = styled(AppButton)`
    width: 100%;
    margin-top: 30px;
`;

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-top: 10px;
`;

export const InputText = styled(AppInputText)`
    &.error {
        margin: 0;
    }
`;

export const InputTextMultiline = styled(AppInputTextMultiline)`
    background: transparent;
    margin: 0;
`;

export const LabelText = styled.p`
    font-size: 15px;
    color: #000;
    font-weight: 400;
    margin: 5px;
`;

export const ErrorText = styled.p`
    font-size: 13px;
    color: red;
    margin: 0px;
    margin-top: 5px;
`;
