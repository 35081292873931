import { observable, action, runInAction, makeObservable } from 'mobx';
import RootStore from './RootStore';
import UserService from '../services/UserService';
import User from '../models/User';
import { APIResponse } from '../models/APIResponse';
import { UserProfile } from '../models/UserProfile';
import { IUserProfile } from '../types/IUserProfile';

class UserStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    //declare variables
    @observable currentUser: User | null = null;
    @observable userProfile: UserProfile | null = null;

    //declare actions
    //get profile user and return an APIResponse with data type is IUser
    @action getProfileDetail = async (params: any) => {
        const result = (await UserService.getInstance().getProfileDetail(
            params
        )) as APIResponse<IUserProfile>;

        if (result && result.isSuccess())
            runInAction(() => {
                //convert json response to User object
                this.userProfile = UserProfile.fromJson(result.data!);
            });
        console.log(this.userProfile);
        return result;
    };
}
export default UserStore;
