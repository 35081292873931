import { Route, Routes, Navigate } from 'react-router-dom';

//import views
import { LoginView } from '../views/LoginView/LoginView';
import { FeedbackView } from '../views/FeedbackView/FeedbackView';
import { NetStationView } from '../views/NetStationView/NetStationView';
import { TicketView } from '../views/TicketView/TicketView';
//import layouts
import MainLayout from '../layouts/MainLayout/MainLayout';

//protected router with private resources must be login
import ProtectedRoute from '../components/ProtectedRoute';

const AppRouters = () => {
    return (
        <Routes>
            <Route element={<MainLayout />}>
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/feedback" element={<FeedbackView />} />
                <Route path="/net-station" element={<NetStationView />} />
                <Route path="/ticket" element={<TicketView />} />
            </Route>
            <Route path="/" element={<LoginView />} />
            <Route path="/login" element={<LoginView />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default AppRouters;
