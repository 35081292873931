import styled from 'styled-components';
import { device } from '../../configs/MediaQuery';

export const TabBarWrapper = styled.div`
    width: 100%;
`;

export const TabBarContainer = styled.div`
    display: flex;
    height: 62px;
    align-items: flex-end;
    border-bottom: solid 1px #e7eaec;

    @media ${device.mobileS} {
        /* position: relative;
        padding-top: 100px; */
    }
    @media ${device.laptop} {
        padding: 0;
    }
`;

export const TabContainer = styled.ul`
    display: flex;
    flex-grow: 1;
    margin: 0;
    padding-left: 0;
    list-style-type: none;
`;

export const TabItem = styled.li`
    @media ${device.mobileS} {
        width: 50%;
    }
    @media ${device.laptop} {
        //width: auto;
        padding-right: 20px;
    }
`;

interface TabLinkProps {
    active: boolean;
}
export const TabLink = styled.a.attrs((props: TabLinkProps) => ({
    className: props.active
}))`
    padding: 17px 17px 17px 17px;
    text-decoration: none;
    font-weight: 700;
    color: #314154;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
        color: #54b4a4;
        font-weight: 600;
        border-bottom: solid 4px #54b4a4;
    }

    @media ${device.mobileS} {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
    }

    @media ${device.laptop} {
        //font-size: 20px;
        line-height: 30px;
    }
`;
