export const API = {
    AUTHENTICATION: {
        LOGIN: 'auth/loginw',
        FORGOT_PASS: 'login/forgetpwd',
        CHANGE_PASS: 'login/changepwd'
    },
    USER: {
        PROFILE_DETAIL: 'user/profile/details',
        FEEDBACK: 'user/feedback',
        ADD_GAME: 'user/add-game'
    },
    NET_STATION: {
        LIST: 'net-station'
    },
    GLOBAL: {
        UPLOAD_IMAGE: 'webhook/upload'
    },
    JSON: {}
};
