import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import {
    AddTicketDialogWrapper,
    AddTicketModal,
    DialogTitleContainer,
    DialogTitleText,
    ErrorText,
    InputText,
    InputTextMultiline,
    InputWrapper,
    LabelText,
    NetStationSelect,
    SendButton
} from './AddTicketDialog.styles';
import { NetStation } from '../../../models/NetStation';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddTicketSchema } from '../../../validation/AddTicketSchema';
import { IAddTicket } from '../../../types/ITicket';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';
import { Modal, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/RootStoreProvider';

interface Props {
    netStation: NetStation;
    visible: boolean;
    onClose: () => void;
}

const { Option } = Select;

export const AddTicketDialog: ComponentType<Props> = observer(
    ({ netStation, visible, onClose }) => {
        const store = useRootStore();
        const { listNetStation } = store.netStationStore;

        const {
            register,
            handleSubmit,
            control,
            reset,
            resetField,
            formState: { errors }
        } = useForm<IAddTicket>({
            resolver: yupResolver(AddTicketSchema),
            defaultValues: {
                netId: netStation.netId
            }
        });

        const onSubmit = (data: IAddTicket) => {
            console.log(data);
            if (1) {
                reset();
                Modal.success({
                    title: 'Gửi yêu cầu thành công',
                    content:
                        'Chúc mừng bạn đã gửi yêu cầu hỗ trợ thành công. Yêu cầu của bạn sẽ được xử lý trong 24h làm việc',
                    centered: true
                });
                onClose();
                return;
            } else {
                Modal.error({
                    title: 'Gửi yêu cầu không thành công',
                    content:
                        'Có lỗi xảy ra. Vui lòng thử lại hoặc liên hệ với bộ phận chăm sóc khách hàng của chúng tôi',
                    centered: true
                });
            }
        };

        const onChangeNetStation = (e: any) => {
            console.log(e);
        };

        return (
            <AddTicketDialogWrapper>
                <AddTicketModal
                    centered
                    onCancel={onClose}
                    open={visible}
                    footer={null}>
                    <DialogTitleContainer>
                        <DialogTitleText>Yêu cầu hỗ trợ</DialogTitleText>
                    </DialogTitleContainer>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputWrapper>
                            <LabelText>Phòng máy cần hỗ trợ</LabelText>
                            <Controller
                                control={control}
                                name="netId"
                                render={({
                                    field: { onChange, value },
                                    fieldState: { invalid, error },
                                    formState
                                }) => (
                                    <Select
                                        defaultValue={netStation.netId}
                                        style={{ width: '100%' }}
                                        value={value}
                                        onChange={(e) => onChange(e)}>
                                        {listNetStation &&
                                            listNetStation?.list.map((item) => {
                                                return (
                                                    <Option
                                                        key={item.netId}
                                                        value={item.netId}>
                                                        {item.netName}
                                                    </Option>
                                                );
                                            })}
                                    </Select>
                                )}
                            />
                            {errors.netId ? (
                                <ErrorText>{errors.netId.message}</ErrorText>
                            ) : null}
                        </InputWrapper>
                        <InputWrapper>
                            <LabelText>Tài khoản UltraView</LabelText>
                            <InputText
                                placeholder=""
                                type="text"
                                {...register('ultraAccount')}
                                isError={errors.ultraAccount ? 'error' : ''}
                            />
                            {errors.ultraAccount ? (
                                <ErrorText>
                                    {errors.ultraAccount.message}
                                </ErrorText>
                            ) : null}
                        </InputWrapper>
                        <InputWrapper>
                            <LabelText>Mật khẩu UltraView</LabelText>
                            <InputText
                                placeholder=""
                                type="text"
                                {...register('ultraPass')}
                                isError={errors.ultraPass ? 'error' : ''}
                            />
                            {errors.ultraPass ? (
                                <ErrorText>
                                    {errors.ultraPass.message}
                                </ErrorText>
                            ) : null}
                        </InputWrapper>
                        <InputWrapper>
                            <LabelText>Nội dung yêu cầu hỗ trợ</LabelText>
                            <InputTextMultiline
                                placeholder=""
                                type="text"
                                {...register('contentRequest')}
                                isError={errors.contentRequest ? 'error' : ''}
                            />
                            {errors.contentRequest ? (
                                <ErrorText>
                                    {errors.contentRequest.message}
                                </ErrorText>
                            ) : null}
                        </InputWrapper>
                        <SendButton
                            typeButton={APP_BUTTON_TYPE.PRIMARY}
                            type="submit">
                            Gửi yêu cầu
                        </SendButton>
                    </form>
                </AddTicketModal>
            </AddTicketDialogWrapper>
        );
    }
);
