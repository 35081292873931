import APIHelper from '../helpers/APIHelper';

class JSonService {
    private static instance: JSonService;

    public static getInstance(): JSonService {
        if (!JSonService.instance) {
            JSonService.instance = new JSonService();
        }
        return JSonService.instance;
    }

    public constructor() {
        //override default baseURL and headers of API service
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
        };
        APIHelper.defaults.headers.common = headers;
        APIHelper.defaults.baseURL = process.env.REACT_APP_CDN_URL;
    }

    getData = async (api: string) => {
        const response: any = await APIHelper.get(api);
        return response.data;
    };
}

export default JSonService;
