import GlobalStore from './GlobalStore';
import UserStore from './UserStore';
import AuthStore from './AuthStore';
import JSonStore from './JSonStore';
import NetStationStore from './NetStationStore';
class RootStore {
    globalStore: GlobalStore;
    userStore: UserStore;
    authStore: AuthStore;
    jsonStore: JSonStore;
    netStationStore: NetStationStore;

    constructor() {
        this.globalStore = new GlobalStore(this);
        this.userStore = new UserStore(this);
        this.authStore = new AuthStore(this);
        this.jsonStore = new JSonStore(this);
        this.netStationStore = new NetStationStore(this);
    }
}
export default RootStore;
