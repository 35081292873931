import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RootStoreProvider } from './providers/RootStoreProvider';
import './i18n';

//fix locale start week from Monday all antd calendar component
import { ConfigProvider } from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import moment from 'moment';
import 'moment/locale/en-gb'; // important!
moment.locale('en-gb'); // important!

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <RootStoreProvider>
        <ConfigProvider locale={en_GB}>
            <App />
        </ConfigProvider>
    </RootStoreProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
