import { ComponentType, useEffect, useState } from 'react';

//components
import { NetStationItem } from './NetStationItem/NetStationItem';
import { NetStationFilter } from './NetStationFilter/NetStationFilter';

import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IGetListNetStationRequest } from '../../types/INetStation';
import { AddTicketDialog } from '../DialogView/AddTicketDialog/AddTicketDialog';
import { NetStation } from '../../models/NetStation';
import { MAIN_MENU } from '../../types/IGlobal';

//styles
import {
    NetStationListWrapper,
    NetStationTitle
} from './NetStationView.styles';
import {
    AppContentContainer,
    AppContentWrapper
} from '../../styles/App.styles';

interface Props {}

const data = [
    {
        netId: 1,
        netName: 'Phòng máy VIP',
        phone: '123456789',
        address: '182 le dai hành p15',
        fromDate: '2023-06-01',
        toDate: '2023-12-01'
    },
    {
        netId: 2,
        netName: 'Phòng máy A',
        phone: '123456789',
        address: '182 le dai hành p15',
        fromDate: '2023-01-01',
        toDate: '2023-04-01'
    },
    {
        netId: 3,
        netName: 'Phòng máy B',
        phone: '123456789',
        address: '182 le dai hành p15',
        fromDate: '2023-06-01',
        toDate: '2023-09-01'
    }
];

export const NetStationView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { listNetStation, getListNetStation } = store.netStationStore;
    const { changeMainMenuIndex } = store.globalStore;

    const [netStation, setNetStation] = useState<NetStation>();
    const [showAddTicket, setShowAddTicket] = useState(false);

    useEffect(() => {
        //onGetListNetStation();
        changeMainMenuIndex(MAIN_MENU.NET_STATION_INFO);
    }, []);

    const onGetListNetStation = async () => {
        const params: IGetListNetStationRequest = {
            data: {
                netName: '',
                phone: '',
                status: 0
            }
        };
        const result = await getListNetStation(params);
    };

    const onRequestAddTicket = async (data: NetStation) => {
        setNetStation(data);
        setShowAddTicket(true);
    };

    return (
        <AppContentWrapper>
            <AppContentContainer>
                <NetStationFilter />
                <NetStationListWrapper>
                    <NetStationTitle>Danh sách phòng máy (03)</NetStationTitle>
                    {data &&
                        data.map((item) => {
                            return (
                                <NetStationItem
                                    key={item.netId}
                                    data={item}
                                    addTicket={onRequestAddTicket}
                                />
                            );
                        })}
                </NetStationListWrapper>
            </AppContentContainer>
            {netStation && showAddTicket ? (
                <AddTicketDialog
                    netStation={netStation!}
                    visible={showAddTicket}
                    onClose={() => setShowAddTicket(false)}
                />
            ) : null}
        </AppContentWrapper>
    );
});
