import styled from 'styled-components';
import { AppButton } from '../../../styles/AppButton.styles';
import { device } from '../../../configs/MediaQuery';

export const NetStationWrapper = styled.div`
    :not(:last-child) {
        border-bottom: 1px solid #9fa3ab;
    }
    padding: 10px;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        margin-left: 40px;
        margin-right: 40px;
    }
`;

export const NetStationContainer = styled.div`
    display: flex;
    @media ${device.mobileS} {
        flex-direction: column;
    }
    @media ${device.laptop} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

export const NetStationInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TitleWrapper = styled.div`
    display: flex;
    padding-bottom: 10px;
`;

export const TitleText = styled.h1`
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    margin-right: 20px;
`;

export const RegisterDurationWrapper = styled.div`
    display: flex;
`;

export const RegisterDurationText = styled.h1`
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
`;

export const DescriptionText = styled.h2`
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #8d9199;
`;

export const ButtonWrapper = styled.div`
    display: flex;
`;

export const RequestSupportButton = styled(AppButton)`
    height: 40px;
    font-size: 14px !important;
    margin-right: 10px;

    @media ${device.mobileS} {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
    }
    @media ${device.laptop} {
        margin: 0;
        margin-right: 10px;
    }
`;

export const ViewDetailButton = styled(RequestSupportButton)`
    width: 60px;
    color: #336699 !important;
    @media ${device.mobileS} {
    }
    @media ${device.laptop} {
        margin: 0;
    }
`;
