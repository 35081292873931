import React, { FC, ReactNode } from 'react';

import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { Outlet } from 'react-router-dom';

// define interface to represent component props
interface Props {}

const MainLayout: FC<Props> = () => {
    return (
        <div>
            <Header></Header>
            <Outlet />
            <Footer />
        </div>
    );
};

export default MainLayout;
