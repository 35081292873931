import { action, makeObservable, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { APIResponseList } from '../models/APIResponse';
import { ListNetStation } from '../models/NetStation';
import { IGetListNetStationRequest, INetStation } from '../types/INetStation';
import { NetStationService } from '../services/NetStationService';

class NetStationStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable listNetStation: ListNetStation | null = null;

    @action getListNetStation = async (
        params: IGetListNetStationRequest,
        loadMore?: boolean
    ) => {
        //init list when load first or null and calc paging
        if (!loadMore || !this.listNetStation)
            runInAction(() => {
                this.listNetStation = new ListNetStation();
            });
        else if (loadMore) {
            this.listNetStation.pageIndex += 1;
            this.listNetStation.isLoading = true; // determine if we're loading data is show process in UI
            params.data.from = params.data.size
                ? params.data.size * this.listNetStation.pageIndex
                : this.listNetStation.pageSize * this.listNetStation.pageIndex;
        }

        //request api service
        const result = (await NetStationService.getInstance().getListNetStation(
            params
        )) as APIResponseList<Array<INetStation>>;

        //check result and binding data if success
        if (result && result.isSuccess()) {
            runInAction(() => {
                if (this.listNetStation) {
                    this.listNetStation.list = this.listNetStation.list?.concat(
                        ListNetStation.fromJson(result.data?.list!)
                    )!;
                    this.listNetStation.totalRecord = result.totalRecord();
                    this.listNetStation.isLoading = false;
                }
            });
        }
        return result;
    };
}

export default NetStationStore;
