import { Modal } from 'antd';
import styled from 'styled-components';
import { device } from '../../configs/MediaQuery';
import { AppButton } from '../../styles/AppButton.styles';

export const LoginWrapper = styled.section`
    max-width: 100%;
`;

export const LoginContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    color: #314154;
`;

export const LoginModal = styled(Modal)`
    .ant-modal-body {
        text-align: center;
    }
`;

export const TitleContainer = styled.div`
    padding-top: 10px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const LoginTitle = styled.h1`
    font-weight: 700;
    margin: 0;
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const LogoApp = styled.img`
    width: 170px;
    height: auto;
    object-fit: cover;
`;

export const LoginButtonWrapper = styled.div`
    display: flex;
    border: solid 1px #dbdfe2;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 16px;
    cursor: pointer;
`;

export const LoginButtonIcon = styled.img`
    height: 20px;
    width: 40px;
    object-fit: none;
`;

export const LoginButtonText = styled.h2`
    font-weight: 600;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const LoginEmailText = styled.p`
    margin-top: 17px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #314154;
`;

interface LoginInputTextProps {
    isError: boolean;
}

export const LoginInputText = styled.input.attrs(
    (props: LoginInputTextProps) => ({
        className: props.isError
    })
)`
    height: 40px;
    border-radius: 8px;
    border: 1px solid #dbdfe2;
    display: flex;
    margin-bottom: 16px;
    outline: none;
    padding-left: 12px;

    &.error {
        border: solid 1px red;
    }

    @media ${device.mobileS} {
        width: 100%;
    }

    @media ${device.laptop} {
        width: 100%;
    }
`;

export const LoginButton = styled(AppButton)`
    @media ${device.mobileS} {
        width: 100%;
    }

    @media ${device.laptop} {
        width: 100%;
    }
    margin-bottom: 20px;
`;

export const ForgetPass = styled.a`
    color: #0046c2;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
`;

export const NoAccountWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
`;

export const NoAccountText = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`;

export const NoAccountLink = styled.a`
    margin-left: 5px;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0046c2;
`;
