import { ComponentType, useEffect, useState } from 'react';
import pure from 'recompose/pure';

//components
import { TicketItem } from './TicketItem/TicketItem';
import { TicketFilter } from './TicketFilter/TicketFilter';
//styles
import {
    AppContentContainer,
    AppContentWrapper
} from '../../styles/App.styles';
import { TicketListWrapper, TicketTitle } from './TicketView.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../providers/RootStoreProvider';
import { MAIN_MENU } from '../../types/IGlobal';
import { TicketCommentDialog } from '../DialogView/TicketCommentDialog/TicketCommentDialog';
import { RatingDialog } from '../DialogView/RatingDialog/RatingDialog';

interface Props {}

export const TicketView: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { changeMainMenuIndex } = store.globalStore;

    const [openCommentDialog, setOpenCommentDialog] = useState(false);
    const [openRatingTicket, setOpenRatingTicket] = useState(false);

    useEffect(() => {
        changeMainMenuIndex(MAIN_MENU.TICKET);
    }, []);

    const onOpenCommentDialog = () => {
        setOpenCommentDialog(true);
    };

    const onOpenRatingDialog = () => {
        setOpenRatingTicket(true);
    };

    return (
        <AppContentWrapper>
            <AppContentContainer>
                <TicketFilter />

                <TicketListWrapper>
                    <TicketTitle>Danh sách yêu cầu (02)</TicketTitle>
                    <TicketItem
                        openCommentDialog={onOpenCommentDialog}
                        openRatingDialog={onOpenRatingDialog}
                    />
                    <TicketItem
                        openCommentDialog={onOpenCommentDialog}
                        openRatingDialog={onOpenRatingDialog}
                    />
                </TicketListWrapper>
            </AppContentContainer>
            {openCommentDialog ? (
                <TicketCommentDialog
                    visible={openCommentDialog}
                    onClose={() => setOpenCommentDialog(false)}
                />
            ) : null}

            {openRatingTicket ? (
                <RatingDialog
                    visible={openRatingTicket}
                    onClose={() => setOpenRatingTicket(false)}
                />
            ) : null}
        </AppContentWrapper>
    );
});
