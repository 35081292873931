import { computed, makeObservable, observable } from 'mobx';
import { APP_CONFIG } from '../configs/GlobalConst';

export abstract class DataList<T> {
    totalRecord: number = 0;
    pageIndex: number = 0;
    pageSize: number = APP_CONFIG.PAGE_SIZE;

    //observable properties
    @observable list: Array<T> = [];
    @observable isLoading: boolean = true;

    @computed get isLoadMore() {
        return this.list.length < this.totalRecord && this.list.length > 0;
    }

    constructor() {
        makeObservable(this);
    }

    getLength(): number {
        return this.list.length;
    }
}
