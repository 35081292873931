import styled from 'styled-components';
import { device } from '../../../configs/MediaQuery';
import { Modal, Rate } from 'antd';
import { AppButton } from '../../../styles/AppButton.styles';

export const RatingDialogWrapper = styled.div``;

export const DialogTitleContainer = styled.div`
    padding-top: 5px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const DialogTitleText = styled.h1`
    font-weight: 700;
    margin: 0;
    @media ${device.mobileS} {
        font-size: 14px;
        line-height: 20px;
    }

    @media ${device.tablet} {
        font-size: 20px;
        line-height: 30px;
    }
`;

export const RatingModal = styled(Modal)`
    .ant-modal-body {
        padding-top: 5px !important;
    }
`;

export const RatingStart = styled(Rate)`
    svg {
        width: 50px !important;
        height: 50px !important;
    }
`;

export const RatingButton = styled(AppButton)`
    width: 100%;
    margin-top: 30px;
    font-size: 14px !important;
`;
