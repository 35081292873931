import { ComponentType } from 'react';
import pure from 'recompose/pure';

//components

//styles
import { LoginButton, UserLoginWrapper } from './UserLogin.styles';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../../providers/RootStoreProvider';
import { APP_BUTTON_TYPE } from '../../../../types/IGlobal';

interface Props {}

export const UserLogin: ComponentType<Props> = observer(({}) => {
    const store = useRootStore();
    const { currentUser, logout } = store.authStore;
    const renderContent = () => {
        if (!currentUser?.isLogin()) {
            return <LoginView />;
        }
    };

    const LoginView = () => {
        return <LoginButton typeButton={APP_BUTTON_TYPE.PRIMARY} />;
    };

    return <UserLoginWrapper>{renderContent()}</UserLoginWrapper>;
});
