import { AxiosResponse } from 'axios';
import { IRequestBase, IResponseBase } from '../types/ITypeBase';

abstract class BaseService {
    //abstract methods
    protected abstract postData(api: string, params: IRequestBase): any;
    protected abstract handleResponse(response: AxiosResponse): IResponseBase;
}

export default BaseService;
