import { ComponentType } from 'react';
import pure from 'recompose/pure';
import {
    FilterContentWrapper,
    InputText,
    InputWrapper,
    LabelText,
    SearchButton,
    SearchButtonWrapper,
    StatusNetStationFilter,
    NetStationFilterContainer,
    NetStationFilterWrapper
} from './NetStationFilter.styles';
import { Col, Collapse, Row, Select } from 'antd';
import { APP_BUTTON_TYPE } from '../../../types/IGlobal';

//components

//styles

interface Props {}

const { Panel } = Collapse;

export const NetStationFilter: ComponentType<Props> = pure(({}) => {
    const onChangeStatusNetStation = () => {};

    return (
        <NetStationFilterWrapper>
            <NetStationFilterContainer defaultActiveKey={1}>
                <Panel header="Tìm kiếm phòng máy" key={1}>
                    <FilterContentWrapper>
                        <Row>
                            <Col xl={6} md={6} sm={12} xs={24}>
                                <InputWrapper>
                                    {/* <LabelText>Mã yêu cầu</LabelText> */}
                                    <InputText
                                        placeholder="Tên phòng máy"
                                        type="text"
                                    />
                                </InputWrapper>
                            </Col>
                            <Col xl={6} md={6} sm={12} xs={24}>
                                <InputWrapper>
                                    {/* <LabelText>Điện thoại khách hàng</LabelText> */}
                                    <InputText
                                        placeholder="Điện thoại"
                                        type="text"
                                    />
                                </InputWrapper>
                            </Col>
                            <Col xl={6} md={6} sm={12} xs={24}>
                                <InputWrapper>
                                    {/* <LabelText>Điện thoại khách hàng</LabelText> */}
                                    <StatusNetStationFilter
                                        defaultValue="0"
                                        style={{ width: '100%' }}
                                        onChange={onChangeStatusNetStation}
                                        options={[
                                            {
                                                value: '0',
                                                label: '-- Tất cả phòng máy --'
                                            },
                                            {
                                                value: '1',
                                                label: 'Sắp hết hạn'
                                            },
                                            {
                                                value: '2',
                                                label: 'Hết hạn'
                                            }
                                        ]}
                                    />
                                </InputWrapper>
                            </Col>
                            <SearchButtonWrapper xl={6} md={6} sm={12} xs={24}>
                                <SearchButton
                                    typeButton={APP_BUTTON_TYPE.PRIMARY}>
                                    Tìm kiếm phòng máy
                                </SearchButton>
                            </SearchButtonWrapper>
                        </Row>
                    </FilterContentWrapper>
                </Panel>
            </NetStationFilterContainer>
        </NetStationFilterWrapper>
    );
});
